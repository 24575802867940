import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { baseUrl } from "../../BaseUrl";

function EditCategory(props) {

    const [file, setFile] = useState("");
    const [defaultFile, setDefaultFile] = useState("");
    const [name, setName] = useState("");
    const [image, setImage] = useState("");

    useEffect(() => {
        setName(props.dishCat.name);
        setImage(props.dishCat.image);
    }, [props])


    const handleEditCategory = () => {
        var bodyFormData = new FormData();
        bodyFormData.set('name', name);
        if (file !== "") {
            bodyFormData.set('image', file);
        }
        bodyFormData.append('token', JSON.parse(localStorage.getItem('nph-admin-token')).token);
        bodyFormData.append('parent_category', props.dishCat.parent_category);
        bodyFormData.append('id', props.dishCat.id);

        fetch(baseUrl + 'edit_category.php', {
            method: 'POST',
            body: bodyFormData
        })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    props.onEdited();
                    props.onClosePress();
                }
            }
            )
            .catch(err => {
                alert(err.message);
            })

    }

    return (
        <Modal isOpen={props.open} className="modal-lg modal-dialog-centered" >
            <ModalHeader className="col-12 d-flex flex-wrap justify-content-between align-items-center" toggle={() => { props.onClosePress() }}>
                Edit Dish Category
            </ModalHeader>
            <ModalBody>
                <div className="col-12 d-flex flex-wrap py-4 poppins-regular">
                    <div className={`col-12 ${props.dishCat.image ? "col-md-7" : ""}`}>
                        <div className="form-group">
                            <label htmlFor="name"><strong>Name</strong></label>
                            <input type="text" className="form-control" placeholder="Name of Dish Category" value={name} onChange={(e) => { setName(e.target.value) }} />
                        </div>
                        {props.dishCat.image ?
                            <div className="form-group">
                                <label htmlFor="img"><strong>Choose Image</strong></label>
                                <input multiple={false} type="file" className="form-control-file" onChange={e => { setFile(e.target.files[0]); setDefaultFile(URL.createObjectURL(e.target.files[0])) }} />
                            </div> : null
                        }
                        <button className="btn btn-primary" onClick={() => { handleEditCategory() }}>Upload</button>
                    </div>
                    {props.dishCat.image ?
                        <div className="col-12 col-md-5">
                            {defaultFile || file ?
                                <img alt="" src={defaultFile} style={{ width: "100%", height: "85%", objectFit: 'cover' }} />
                                :
                                <img alt="" src={`${baseUrl}${image}`} style={{ width: "100%", height: "85%", objectFit: 'cover' }} />
                            }
                        </div> : null
                    }
                </div>
            </ModalBody>
        </Modal>
    );
}
export default EditCategory;