import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import Collapsible from './Collapsible';
import * as GrIcons from 'react-icons/gr';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import useMenu from "../../Hooks/useMenu";
import { baseUrl } from "../../BaseUrl";
import DeleteCategory from "./DeleteCategory";
import EditCategory from "./EditCategory";
import DeleteMenu from "./DeleteMenu";
import EditMenu from "./EditMenu";

function Menu(props) {

    const [menuItems, setMenuItems] = useState([]);
    const [query, setQuery] = useState("");
    const [menu, setMenu] = useState([]);
    const [dishName, setDishName] = useState("");
    const [dishDescription, setDishDescription] = useState(null);
    const [dishFile, setDishFile] = useState("");
    const [dishDefaultFile, setDishDefaultFile] = useState("");
    const [parentCatId, setParentCatId] = useState(null);
    const [catFile, setCatFile] = useState("");
    const [catDefaultFile, setCatDefaultFile] = useState("");
    const [catName, setCatName] = useState("");

    const [subCatId, setSubCatId] = useState(null);

    const [dishCatOpt, setDishCatOpt] = useState({});

    // Price Tag
    const [priceTags, setPriceTags] = useState([{ tag: null, price: null }]);

    const animatedComponents = makeAnimated();


    // Delete Category
    const [isCatModalOpen, setIsCatModalOpen] = useState(false);
    const [catId, setCatId] = useState(null);

    // Edit Category
    const [isEditCatModalOpen, setIsEditCatModalOpen] = useState(false);
    const [category, setCategory] = useState({});

    // Delete Category
    const [isDishModalOpen, setIsDishModalOpen] = useState(false);
    const [dishId, setDishId] = useState(null);

    // Edit Category
    const [isEditDishModalOpen, setIsEditDishModalOpen] = useState(false);
    const [dish, setDish] = useState({});

    React.useEffect(() => {
        fetchMenu();




    }, [catName]);

    const fetchMenu = async () => {
        await fetch(baseUrl + "categories.php")
            .then(response => response.json())
            .then(data => {
                setMenuItems(data);
                var tempArr = data.map(e => e.sub_category.map(f => f.menu_item).reduce((a, b) => a.concat(b), [])).reduce((a, b) => a.concat(b), []);
                setMenu(tempArr);
                const dishOptions = data.map((dishCat) => {
                    return (
                        { value: dishCat.id, label: dishCat.name }
                    );
                })
                setDishCatOpt(dishOptions);
            })
            .catch(error => { console.log(error.message) });
    }

    const addCategory = (e) => {
        e.preventDefault();
        var bodyFormData = new FormData();
        bodyFormData.append('name', catName);
        bodyFormData.append('token', JSON.parse(localStorage.getItem('nph-admin-token')).token);
        bodyFormData.append('parent_category', parentCatId ?? 0);
        if (catFile !== "") {
            bodyFormData.append('image', catFile, catFile.name);
        }
        fetch(baseUrl + "add_category.php", {
            method: "POST",
            body: bodyFormData
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert("Category added successfully");
                    window.location.reload();
                } else {
                    alert("Something went wrong");
                }
            }
            )
    }

    const addDish = (e) => {
        e.preventDefault();
        var bodyFormData = new FormData();
        bodyFormData.append('name', dishName);
        bodyFormData.append('description', dishDescription);
        bodyFormData.append('token', JSON.parse(localStorage.getItem('nph-admin-token')).token);
        bodyFormData.append('category_id', subCatId);
        bodyFormData.append('price', JSON.stringify(priceTags));
        if (dishFile !== "") {
            bodyFormData.append('image', dishFile, dishFile.name);
        }
        fetch(baseUrl + "add_menu_item.php", {
            method: "POST",
            body: bodyFormData
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert("Dish added successfully");
                    window.location.reload();
                } else {
                    alert("Something went wrong");
                }
            }
            )
    }

    const deleteCategory = () => {
        var bodyFormData = new FormData();
        bodyFormData.append('id', catId);
        bodyFormData.append('token', JSON.parse(localStorage.getItem('nph-admin-token')).token);
        fetch(baseUrl + "delete_category.php", {
            method: "POST",
            body: bodyFormData
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert("Category deleted successfully");
                    setIsCatModalOpen(false);
                    setCatId(null);
                    fetchMenu();
                } else {
                    alert("Something went wrong");
                }
            }
            )

    }

    const deleteDish = () => {
        var bodyFormData = new FormData();
        bodyFormData.append('id', dishId);
        bodyFormData.append('token', JSON.parse(localStorage.getItem('nph-admin-token')).token);
        fetch(baseUrl + "delete_menu.php", {
            method: "POST",
            body: bodyFormData
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert("Menu deleted successfully");
                    setIsDishModalOpen(false);
                    setDishId(null);
                    fetchMenu();
                } else {
                    alert("Something went wrong");
                }
            }
            )

    }

    return (
        <div className="p-3 p-sm-3 p-md-5 mt-5">
            <div className="col-12 d-flex justify-content-between mt-5 mt-md-0">
                <h2 className="font-weight-bolder">Our Menu</h2>
            </div>
            <div className="d-flex flex-wrap">
                <div className="col-12 col-xl-8 no-gutters order-2 order-xl-1 mt-3 ">
                    <div className="col-12 mb-2">
                        <input className="form-control col-12 border shadow" placeholder="Search Dish . . . ." onChange={(e) => { setQuery(e.target.value) }} />
                    </div>
                    {query.length <= 0 ? <>
                        {menuItems.map((item, index) => {
                            return (
                                <Collapsible
                                    key={index}
                                    title={item.name}
                                    extraClass={"lead"}
                                    image={item.image}
                                    isEmpty={item.sub_category.length === 0 ? true : false}
                                    onDeletePress={() => {
                                        setIsCatModalOpen(true);
                                        setCatId(item.id);
                                    }}
                                    isEdit={true}
                                    onEditPress={() => {
                                        setIsEditCatModalOpen(true);
                                        setCategory(item);
                                    }}
                                >
                                    <div className="pl-2 pl-md-4">
                                        {item.sub_category.map((subCat, index) =>
                                            <Collapsible
                                                key={index}
                                                title={subCat.name}
                                                extraClass={"lead"}
                                                image={baseUrl + subCat.image}
                                                isEmpty={subCat.menu_item.length === 0 ? true : false}
                                                onDeletePress={() => {
                                                    setIsCatModalOpen(true);
                                                    setCatId(subCat.id);
                                                }}
                                                isEdit={true}
                                                onEditPress={() => {
                                                    setIsEditCatModalOpen(true);
                                                    setCategory(subCat);
                                                }}
                                            >
                                                <div className="col-12 d-flex flex-wrap justify-content-start mt-2 px-1 px-md-2">
                                                    {subCat.menu_item.map((menuItem, index) => {
                                                        return (
                                                            <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 p-0 p-md-2">
                                                                <div className=" col-12 bg-white shadow border rounded p-2">
                                                                    <img src={baseUrl + menuItem.image} style={{ height: 200, width: "100%", objectFit: "cover" }} />
                                                                    <div className="col-12 d-flex justify-content-between mt-2 px-1">
                                                                        <p><strong>{menuItem.name}</strong></p>
                                                                        <div className="d-flex flex-wrap justify-content-between px-0">
                                                                            <div className="d-flex">
                                                                                <GrIcons.GrEdit style={{ cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        setIsEditDishModalOpen(true);
                                                                                        setDish(menuItem);
                                                                                    }}
                                                                                />
                                                                                <GrIcons.GrTrash
                                                                                    style={{ cursor: 'pointer', marginLeft: 5 }}
                                                                                    onClick={() => {
                                                                                        setIsDishModalOpen(true);
                                                                                        setDishId(menuItem.id);
                                                                                    }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <span className="small fw-bold">{menuItem.description}</span>
                                                                    <div className="d-flex px-1 flex-wrap">
                                                                        {menuItem.price.map((price, index) =>
                                                                            <div key={index} className="pr-2 mr-2" style={{ borderRight: "1px solid #00000050" }}>
                                                                                <span className="small">{price.tag === "" ? "Price" : price.tag}</span><br />
                                                                                <span className="small">Rs. {parseInt(price.price)}</span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    )}

                                                </div>
                                            </Collapsible>
                                        )}
                                    </div>

                                </Collapsible>
                            );
                        })}</> :
                        <div className="col-12 d-flex flex-wrap justify-content-start mt-2">
                            {menu.filter((e) => e.name.toLowerCase().includes(query.toLowerCase())).map((menuItem, index) => {
                                return (
                                    <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3 p-2">
                                        <div className=" col-12 bg-white shadow border rounded p-2">
                                            <img src={baseUrl + menuItem.image} style={{ height: 200, width: "100%", objectFit: "cover" }} />
                                            <div className="col-12 d-flex justify-content-between mt-2 px-1">
                                                <p><strong>{menuItem.name}</strong></p>
                                                <div className="d-flex flex-wrap justify-content-between px-0">
                                                    <div className="d-flex">
                                                        <GrIcons.GrEdit style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setIsEditDishModalOpen(true);
                                                                setDish(menuItem);
                                                            }}
                                                        />
                                                        <GrIcons.GrTrash
                                                            style={{ cursor: 'pointer', marginLeft: 5 }}
                                                            onClick={() => {
                                                                setIsDishModalOpen(true);
                                                                setDishId(menuItem.id);
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="small fw-bold">{menuItem.description}</span>
                                            <div className="d-flex px-1 flex-wrap">
                                                {menuItem.price.map((price, index) =>
                                                    <div key={index} className="pr-2 mr-2" style={{ borderRight: "1px solid #00000050" }}>
                                                        <span className="small">{price.tag === "" ? "Price" : price.tag}</span><br />
                                                        <span className="small">Rs. {parseInt(price.price)}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            )}

                        </div>
                    }
                </div>


                <div className="col-12 col-xl-4 no-gutters order-1 order-xl-2 mt-3 ">
                    <Collapsible title="Add a Dish Category">
                        <div className="p-2 m-2 shadow-sm border rounded">
                            <form onSubmit={addCategory}>
                                <div className="form-group">
                                    <label><strong>Category Name:</strong></label>
                                    <input required placeholder="Category name" className="form-control" value={catName} onChange={(e) => { setCatName(e.target.value) }} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="dish"><strong>Parent Category:</strong><span className="small">(Do not select for main category!)</span></label>

                                    <Select
                                        required={catFile === "" ? false : true}
                                        closeMenuOnSelect={true}
                                        components={animatedComponents}
                                        defaultValue
                                        isMulti={false}
                                        options={dishCatOpt}
                                        placeholder="Select dish category"
                                        value={menuItems.find((u) => u.value === parentCatId)} // set selected values
                                        onChange={(e) => setParentCatId(e.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label><strong>Category Image:</strong><span className="small">(Do not select for main category!)</span></label>
                                    <input accept="image/png, image/jpeg" required={parentCatId === null ? false : true} multiple={false} className="form-control-file" type="file" onChange={e => { setCatFile(e.target.files[0]); setCatDefaultFile(URL.createObjectURL(e.target.files[0])) }} />
                                </div>
                                <button className="btn btn-primary">Add Category</button>
                            </form>

                            <img alt="" className="mt-2" src={catDefaultFile} style={{ width: "100%", height: "85%", objectFit: 'cover', display: catDefaultFile || catFile ? "block" : 'none' }} />
                        </div>
                    </Collapsible>
                    <Collapsible title="Add a Dish">
                        <div className="p-2 m-2 shadow-sm border rounded">
                            <form onSubmit={addDish}>
                                <div className="form-group">
                                    <label><strong>Dish Name:</strong></label>
                                    <input type="text" required placeholder="Dish name" className="form-control" value={dishName} onChange={(e) => { setDishName(e.target.value) }} />
                                </div>
                                <div className="form-group">
                                    <label><strong>Dish Description:</strong></label>
                                    <textarea type="number" step="any" required placeholder="Dish Description" className="form-control" onChange={(e) => { setDishDescription(e.target.value) }}>{dishDescription}</textarea>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="dish"><strong>Dish Category:</strong></label>
                                    <select className="form-control" onChange={(e) => { setSubCatId(e.target.value) }}>
                                        <option value="" hidden selected>Select dish category</option>
                                        {menuItems.map((item, index) => {
                                            return (
                                                <optgroup key={index} label={item.name}>
                                                    {item.sub_category.map((subItem, subIndex) => {
                                                        return (
                                                            <option key={subIndex} value={subItem.id}>{subItem.name}</option>
                                                        );
                                                    })}
                                                </optgroup>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <div className="col-12 d-flex flex-wrap justify-content-between mb-2 px-0">
                                        <label><strong>Dish Price:</strong></label>
                                        <button onClick={() => {
                                            var temp = priceTags;
                                            temp.push({ tag: null, price: null });
                                            setPriceTags([...temp]);
                                        }} type="button" className="btn btn-primary">Add +</button>
                                    </div>
                                    {priceTags.map((item, index) => {
                                        return (
                                            <div key={index} className="d-flex mb-2">
                                                <input
                                                    value={item.tag}
                                                    onChange={(e) => {
                                                        var temp = priceTags;
                                                        temp[index].tag = e.target.value;
                                                        setPriceTags([...temp]);
                                                    }}
                                                    required={priceTags.length > 1 ? true : false} placeholder="Tag" className="col-5 form-control" />
                                                <input
                                                    value={priceTags[index].price}
                                                    onChange={(e) => {
                                                        var tempPT = priceTags;
                                                        tempPT[index].price = e.target.value;
                                                        setPriceTags([...tempPT]);
                                                    }}
                                                    type="number"
                                                    required placeholder="Amount (Rs.)" className="col-5 form-control" />
                                                <button onClick={() => {
                                                    var tempPT = priceTags;
                                                    tempPT.splice(index, 1);
                                                    setPriceTags([...tempPT]);
                                                }} type="button" className={`btn btn-danger col-2 ${index === 0 ? "d-none" : ""}`}>X</button>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="form-group">
                                    <label><strong>Dish Image:</strong></label>
                                    <input accept="image/png, image/jpeg" multiple={false} className="form-control-file" type="file" onChange={e => { setDishFile(e.target.files[0]); setDishDefaultFile(URL.createObjectURL(e.target.files[0])) }} />
                                </div>
                                <button type="submit" className="btn btn-primary">Add Dish</button>
                            </form>

                            <img alt="" className="mt-2" src={dishDefaultFile} style={{ width: "100%", height: "85%", objectFit: 'cover', display: dishDefaultFile || catFile ? "block" : 'none' }} />
                        </div>
                    </Collapsible>
                </div>
            </div>
            <DeleteCategory open={isCatModalOpen} onDelete={() => { deleteCategory() }} onClosePress={() => { setIsCatModalOpen(false) }} />
            <EditCategory dishCat={category} onEdited={() => { fetchMenu(); }} open={isEditCatModalOpen} onClosePress={() => { setIsEditCatModalOpen(false) }} />

            <DeleteMenu open={isDishModalOpen} onDelete={() => { deleteDish(); }} onClosePress={() => { setIsDishModalOpen(false); }} />
            <EditMenu open={isEditDishModalOpen} menuItem={dish} onEdited={() => { fetchMenu(); }} onClosePress={() => { setIsEditDishModalOpen(false); }} />
        </div>
    );
}
export default Menu;