import React from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

function DeleteCategory(props) {
    return (
        <Modal isOpen={props.open} className=" modal-dialog-centered" >
            <ModalHeader className="col-12 d-flex flex-wrap justify-content-between align-items-center" toggle={() => { props.onClosePress() }}>
                Delete Dish Category?
            </ModalHeader>
            <ModalBody>
                <p className="poppins-regular">Are you sure you want to delete this dish category?</p>
                <button onClick={() => { props.onDelete(); }} className="btn btn-primary poppins-regular">Confirm</button>
            </ModalBody>
        </Modal>
    );
}
export default DeleteCategory;