import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { baseUrl } from "../../BaseUrl";

function EditCategory(props) {

    const [file, setFile] = useState("");
    const [defaultFile, setDefaultFile] = useState("");
    const [name, setName] = useState("");
    const [dishDescription, setDishDescription] = useState("");
    const [price, setPrice] = useState("");
    const [image, setImage] = useState("");


    // Price Tag
    const [priceTags, setPriceTags] = useState([{ tag: null, price: null }]);

    useEffect(() => {
        setName(props.menuItem.name);
        setDishDescription(props.menuItem.description);
        setPriceTags(props.menuItem.price ?? [{ tag: null, price: null }]);
        setImage(props.menuItem.image);
    }, [props])



    const handleEditDish = async () => {
        var bodyFormData = new FormData();
        bodyFormData.append('id', props.menuItem.id);
        bodyFormData.append('name', name);
        bodyFormData.append('description', dishDescription);
        bodyFormData.append('token', JSON.parse(localStorage.getItem('nph-admin-token')).token);
        bodyFormData.append('price', JSON.stringify(priceTags));
        if (file !== "") {
            bodyFormData.append('image', file, file.name);
        }

        fetch(baseUrl + "edit_menu_item.php", {
            method: 'POST',
            body: bodyFormData
        })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    alert("Successfully Edited");
                    props.onEdited();
                    props.onClosePress();
                } else {
                    alert(res.message);
                }
            })
            .catch(err => { alert(err.message) });

    }


    return (
        <Modal isOpen={props.open} className="modal-lg modal-dialog-centered" >
            <ModalHeader className="col-12 d-flex flex-wrap justify-content-between align-items-center" toggle={() => { props.onClosePress() }}>
                Edit Dish Category
            </ModalHeader>
            <ModalBody>
                <div className="col-12 d-flex flex-wrap py-4 poppins-regular">
                    <div className="col-12 col-md-7">
                        <div className="form-group">
                            <label htmlFor="name"><strong>Name</strong></label>
                            <input type="text" className="form-control" placeholder="Name of Dish Category" value={name} onChange={(e) => { setName(e.target.value) }} />
                        </div>
                        <div className="form-group">
                            <label><strong>Dish Description:</strong></label>
                            <textarea type="number" step="any" required placeholder="Dish Description" className="form-control" onChange={(e) => { setDishDescription(e.target.value) }}>{dishDescription}</textarea>
                        </div>
                        <div>
                            <div className="col-12 d-flex flex-wrap justify-content-between mb-2 px-0">
                                <label><strong>Dish Price:</strong></label>
                                <button onClick={() => {
                                    var temp = priceTags;
                                    temp.push({ tag: null, price: null });
                                    setPriceTags([...temp]);
                                }} type="button" className="btn btn-primary">Add +</button>
                            </div>
                            {priceTags.map((item, index) => {
                                return (
                                    <div key={index} className="d-flex mb-2">
                                        <input
                                            value={item.tag}
                                            onChange={(e) => {
                                                var temp = priceTags;
                                                temp[index].tag = e.target.value;
                                                setPriceTags([...temp]);
                                            }}
                                            required={priceTags.length > 1 ? true : false} placeholder="Tag" className="col-5 form-control" />
                                        <input
                                            value={parseInt(priceTags[index].price)}
                                            onChange={(e) => {
                                                var tempPT = priceTags;
                                                tempPT[index].price = e.target.value;
                                                setPriceTags([...tempPT]);
                                            }}
                                            type="number"
                                            required placeholder="Amount (Rs.)" className="col-5 form-control" />
                                        <button onClick={() => {
                                            var tempPT = priceTags;
                                            tempPT.splice(index, 1);
                                            setPriceTags([...tempPT]);
                                        }} type="button" className={`btn btn-danger col-2 ${index === 0 ? "d-none" : ""}`}>X</button>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="form-group">
                            <label htmlFor="img"><strong>Choose Image</strong></label>
                            <input multiple={false} type="file" className="form-control-file" onChange={e => { setFile(e.target.files[0]); setDefaultFile(URL.createObjectURL(e.target.files[0])) }} />
                        </div>
                        <button className="btn btn-primary" onClick={() => { handleEditDish() }}>Upload</button>
                    </div>
                    <div className="col-12 col-md-5">
                        {defaultFile || file ?
                            <img alt="" src={defaultFile} style={{ width: "100%", height: "85%", objectFit: 'cover' }} />
                            :
                            <img alt="" src={`${baseUrl}${image}`} style={{ width: "100%", height: "85%", objectFit: 'cover' }} />
                        }
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}
export default EditCategory;