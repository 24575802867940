import React from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

function DeleteMenu(props) {
    return (
        <Modal isOpen={props.open} className=" modal-dialog-centered" >
            <ModalHeader className="col-12 d-flex flex-wrap justify-content-between align-items-center" toggle={() => { props.onClosePress() }}>
                Delete Dish?
            </ModalHeader>
            <ModalBody>
                <p className="poppins-regular">Are you sure you want to delete this dish?</p>
                <button onClick={() => { props.onDelete(); }} className="btn btn-primary poppins-regular">Confirm</button>
            </ModalBody>
        </Modal>
    );
}
export default DeleteMenu;