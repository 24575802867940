import React, { useState } from "react";
import { Collapse } from 'reactstrap';
import * as GrIcons from 'react-icons/gr';

export default function Collapsible(props) {


    const [isOpen, setIsOpen] = useState(false);


    return (
        <div className="d-flex flex-column">
            <div className="col-12 py-2 shadow rounded mb-2 d-flex justify-content-between pr-5" onClick={() => setIsOpen(!isOpen)} style={{ backgroundColor: isOpen ? "#fff" : "#fff", cursor: "pointer" }}>
                <div className="col-10 d-flex flex-wrap justify-content-start">
                    {props.image ?
                        <img src={props.image} className="rounded mr-3" style={{ height: 100, width: 100, objectFit: "cover" }} />
                        : null
                    }
                    <p className={`my-auto font-weight-bold text-dark ${props.extraClass}`}>{props.title}</p>
                </div>
                <div className="d-flex flex-start">
                    <GrIcons.GrEdit className="my-auto" onClick={() => { props.onEditPress() }} style={{ display: props.isEdit ? "block" : "none" }} />
                    <GrIcons.GrTrash className="ml-2 my-auto" onClick={() => { props.onDeletePress() }} style={{ display: props.isEmpty ? "block" : "none" }} />
                    <span className="text-dark my-auto lead">{isOpen ? <span>&#x025B4;</span> : <span>&#x025BE;</span>}</span>
                </div>
            </div>
            <Collapse isOpen={isOpen}>
                {props.children}
            </Collapse>
        </div>
    );

}