import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { baseUrl } from "../../BaseUrl";

function AddOfferModal(props) {

    const [file, setFile] = useState("");
    const [defaultFile, setDefaultFile] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");

    // useEffect(() => {
    //     setName(props.offer.name);
    //     setDescription(props.offer.description);
    //     setImage(props.offer.image);
    // }, [props])

    const handleAddOffer = async (e) => {
        e.preventDefault();
        var bodyFormData = new FormData();
        bodyFormData.append('name', name);
        bodyFormData.append('description', description);
        bodyFormData.append('token', JSON.parse(localStorage.getItem('nph-admin-token')).token);
        bodyFormData.append('image', file, file.name);

        fetch(baseUrl + "add_offer.php", {
            method: 'POST',
            body: bodyFormData
        })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    alert("Successfully Added");
                    props.onAdded();
                    props.onClosePress();
                } else {
                    alert(res.message);
                }
            })
            .catch(err => { alert(err.message) });

    }


    return (
        <Modal isOpen={props.open} className="modal-lg modal-dialog-centered" >
            <ModalHeader className="col-12 d-flex flex-wrap justify-content-between align-items-center" toggle={() => { props.onClosePress() }}>
                Add Offer
            </ModalHeader>
            <ModalBody>
                <div className="col-12 d-flex flex-wrap py-4 poppins-regular">
                    <form onSubmit={handleAddOffer} className="col-12 col-md-7">
                        <div className="form-group">
                            <label htmlFor="name"><strong>Name</strong></label>
                            <input required type="text" className="form-control" placeholder="Name of Offer" value={name} onChange={(e) => { setName(e.target.value) }} />
                        </div>
                        <div className="form-group">
                            <label><strong>Description:</strong></label>
                            <textarea required type="number" step="any" required placeholder="Offer Description" className="form-control" onChange={(e) => { setDescription(e.target.value) }}>{description}</textarea>
                        </div>

                        <div className="form-group">
                            <label htmlFor="img"><strong>Choose Image</strong></label>
                            <input required multiple={false} type="file" className="form-control-file" onChange={e => { setFile(e.target.files[0]); setDefaultFile(URL.createObjectURL(e.target.files[0])) }} />
                        </div>
                        <button className="btn btn-primary" type="submit">Upload</button>

                    </form>
                    <div className="col-12 col-md-5">
                        {defaultFile || file ?
                            <img alt="" src={defaultFile} style={{ width: "100%", height: "85%", objectFit: 'cover' }} />
                            :
                            null
                        }
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}
export default AddOfferModal;