import logo from './logo.svg';
import './App.css';
import Main from './Components/Main';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <div className="poppins-regular ">
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </div >
  );
}

export default App;
