import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Login from "./Login";
import Menu from "./Menu/Menu";
import Navbar from "./Navbar/Navbar";
import Offers from "./Offers/Offers";

function Main(props) {

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("nph-admin-token") !== null) {
            setIsLoggedIn(true);
        }
    }, [])

    return (
        <div>
            {isLoggedIn ?
                <>
                    <Navbar toggleLogin={() => { setIsLoggedIn(false) }} />
                    <Switch>
                        <Route exact path="/" component={Menu} />
                        <Route exact path="/offers" component={Offers} />
                        <Redirect to="/" />
                    </Switch>
                </> : <Login isLoggedIn={() => { setIsLoggedIn(true) }} />}
        </div>
    );
}
export default Main;