import React, { useState } from "react";
import { baseUrl } from "../BaseUrl";

function Login(props) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [status, setStatus] = useState("");


    const handleLogin = (e) => {
        e.preventDefault();
        if (username === "" && password === "") {
            setStatus("Please enter username and password");
        } else if (username === "" || username === " ") {
            setStatus("Please enter username");
        } else if (password === "" || password === " ") {
            setStatus("Please enter password");
        } else {
            setStatus("");
            login(username, password);
        }
    }

    const login = (username, password) => {
        var bodyFormData = new FormData();
        bodyFormData.set('email', username);
        bodyFormData.set('password', password);
        fetch(baseUrl + "login.php", {
            method: "POST",
            body: bodyFormData
        })
            .then(res => res.json())
            .then(res => {
                if (res.success) {
                    localStorage.setItem('nph-admin-token', JSON.stringify(res));
                    setStatus('Successfull');
                    props.isLoggedIn();
                } else {
                    setStatus(res.message);
                }
            })
            .catch(err => { alert(err.message) })
    }

    return (
        <div style={{ height: "100vh" }} className="w-100 d-flex justify-content-center align-items-center">

            <div className="shadow-lg rounded p-5">
                <form onSubmit={handleLogin}>
                    <label><h4>Namaste Palpa Hotel And Restaurant</h4></label><br />
                    <label><p className={`small ${status === "Successfull" ? 'text-success' : "text-danger"}`}>{status}</p></label>
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input name="username" placeholder="Username" type="text" className="form-control" required
                            onChange={(event) => {
                                setUsername(event.target.value)
                            }} value={username}

                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="Password">Password</label>
                        <input name="password" placeholder="Password" type="password" className="form-control" required
                            onChange={(event) => { setPassword(event.target.value) }} value={password}
                        />
                    </div>
                    <button className="btn btn-primary btn-block">Login</button>
                </form>
            </div>
            {/* Success and Unsuccess Modal */}
            {/* <SuccessModal open={success} />
            <UnsuccessModal open={unsuccess} /> */}
        </div>
    );
}
export default Login;