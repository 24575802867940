import React, { useEffect, useState } from "react";
import { baseUrl } from "../../BaseUrl";
import AddOfferModal from "./AddOfferModal";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import * as FaIcons from "react-icons/fa";
import EditOfferModal from "./EditOfferModal";

function Offers(props) {

    const [offers, setOffers] = useState([]);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [offer, setOffer] = useState({});
    const [isDeleteOfferModal, setIsDeleteOfferModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchOffers();
    }, [])

    const fetchOffers = async () => {
        await fetch(baseUrl + "offers.php")
            .then(response => response.json())
            .then(data => {
                setOffers(data);
                setIsLoading(false);

            })
            .catch(error => {
                console.log(error.message);
                setIsLoading(false);
            });
    }

    const deleteOffer = async () => {
        var bodyFormData = new FormData();
        bodyFormData.append('id', offer.id);
        bodyFormData.append('token', JSON.parse(localStorage.getItem('nph-admin-token')).token);
        await fetch(baseUrl + "delete_offer.php?", {
            method: 'POST',
            body: bodyFormData
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert("Successfully Deleted");
                    setIsDeleteOfferModal(false);
                    fetchOffers();
                } else {
                    alert(data.message);
                }
            })
            .catch(error => { console.log(error.message) });
    }


    return (
        <div className="p-3 p-sm-3 p-md-5 mt-5">
            <div className="col-12 d-flex justify-content-between  mt-5 mt-md-0">
                <h2 className="font-weight-bolder">Our Offers</h2>
                <button className="btn btn-primary" onClick={() => { setIsAddModalOpen(true); }}>Add Offer</button>
            </div>
            <div className="d-flex flex-wrap mt-3">

                {isLoading ? <div className="d-flex flex-wrap justify-content-center">
                    <div class="spinner-grow text-primary" role="status">
                    </div>
                    <div><h3>Loading...</h3></div>
                </div> : <>
                    {offers.length > 0 ?
                        <>
                            {offers.map((offer, index) => {
                                return (
                                    <div key={index} className="col-12 col-md-6 col-lg-4 px-0 mt-2" style={{ flex: 1 }}>
                                        <div className="card mx-1" style={{ height: "100%" }}>
                                            <img style={{ height: "200px", objectFit: "cover" }} src={baseUrl + offer.image} className="card-img-top" alt={offer.name} />
                                            <div className="card-body d-flex flex-column justify-content-between">
                                                <h5 className="card-title">{offer.name}</h5>
                                                <p className="card-text">{offer.description}</p>
                                                <div className="d-flex flex-wrap">
                                                    <button onClick={() => { setOffer(offer); setIsViewModalOpen(true); }} className="btn btn-primary">View</button>
                                                    <button onClick={() => { setOffer(offer); setIsEditModalOpen(true); }} className="btn btn-warning">Edit</button>
                                                    <button onClick={() => { setOffer(offer); setIsDeleteOfferModal(true); }} className="btn btn-danger">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </> : <>
                            <h3>No Offers for now!</h3>
                        </>}
                </>}

            </div>
            <AddOfferModal onAdded={() => { fetchOffers(); }} onClosePress={() => { setIsAddModalOpen(false); }} open={isAddModalOpen} />
            <EditOfferModal offer={offer} onEdited={() => { fetchOffers(); }} onClosePress={() => { setIsEditModalOpen(false); }} open={isEditModalOpen} />

            <Modal isOpen={isViewModalOpen} className="modal-lg modal-dialog-centered" >
                <ModalBody>
                    <div className="col-12 d-flex justify-content-end">
                        <FaIcons.FaTimes style={{ cursor: "pointer" }} size={25} onClick={() => { setIsViewModalOpen(false); }} className="head-icon mb-2" color="#000" />
                    </div>
                    <div className="card mx-1" style={{ height: "100%" }}>
                        <img src={baseUrl + offer.image} className="card-img-top" alt={offer.name} />
                        <div className="card-body">
                            <h5 className="card-title">{offer.name}</h5>
                            <p className="card-text">{offer.description}</p>
                            {/* <button onClick={() => { setOffer(offer); isModalOpen(true); }} className="btn btn-primary">View</button> */}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={isDeleteOfferModal} className=" modal-dialog-centered" >
                <ModalHeader className="col-12 d-flex flex-wrap justify-content-between align-items-center" toggle={() => { setIsDeleteOfferModal(false) }}>
                    Delete Offer?
                </ModalHeader>
                <ModalBody>
                    <p className="poppins-regular">Are you sure you want to delete this offer?</p>
                    <button className="btn btn-primary poppins-regular" onClick={deleteOffer}>Confirm</button>
                </ModalBody>
            </Modal>
        </div>
    );
}
export default Offers;